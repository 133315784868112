import { useCallback } from 'react';

export enum StorageKeys {
  MANAGER = '@gestor-acesso',
  MEDICAL = '@medico-acesso',
  HASH = 'hash',
  OPC_CODIGO = 'opc_codigo',
}

export const useStorage = () => {
  const set = useCallback(<T>(key: string, value: T) => {
    if (typeof value === 'string') {
      return sessionStorage.setItem(key, value);
    }

    if (value) {
      return sessionStorage.setItem(key, JSON.stringify(value));
    }

    return sessionStorage.removeItem(key);
  }, []);

  const get = useCallback(<T>(key: string): T => {
    try {
      return JSON.parse(sessionStorage.getItem(key) || '');
    } catch (error) {
      return sessionStorage.getItem(key) as T;
    }
  }, []);

  const remove = useCallback(
    (key: string) => sessionStorage.removeItem(key),
    []
  );

  const clear = useCallback(() => sessionStorage.clear(), []);

  return {
    get,
    set,
    remove,
    clear,
  };
};
