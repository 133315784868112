import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { client } from 'adapters/http';
import { MedicoDermato } from 'models/medical.model';
import { ProfileDermato } from 'models/profile.model';

import { StorageKeys, useStorage } from '../hooks/useStorage';

export const useAuth = () => {
  const [loading, setLoading] = useState(false);

  const [profile, setProfile] = useState<ProfileDermato | null>(null);

  const [searchParams] = useSearchParams();

  const { get, set } = useStorage();

  const handleStoreProfile = useCallback(
    (data: ProfileDermato) => {
      setProfile(data);
      set(StorageKeys.MANAGER, data);
      setLoading(false);
    },
    [set]
  );

  const getProfile = useCallback(
    async (hash: string, opc_codigo: string) => {
      setLoading(true);
      const params = { params: { hash, opc_codigo } };

      client
        .get<ProfileDermato>('/dermato/perfil', params)
        .then(({ data }) => handleStoreProfile(data));
    },
    [handleStoreProfile]
  );

  const checkIfWasLogged = useCallback(
    (hash: string | null, opcCodigo: string | null) => {
      const currentProfile = get<ProfileDermato>(StorageKeys.MANAGER);

      if (currentProfile?.perfil) {
        return handleStoreProfile(currentProfile);
      }

      if (hash && opcCodigo) {
        return getProfile(String(hash), String(opcCodigo));
      }

      // window.location.href = String(process.env.REACT_APP_INTRANET_SESC);
      return '';
    },
    [get, getProfile, handleStoreProfile]
  );

  useEffect(() => {
    const hash = searchParams.get('hash');

    const opcCodigo = searchParams.get('opc_codigo');

    if (hash && opcCodigo) {
      set(StorageKeys.HASH, hash);

      set(StorageKeys.OPC_CODIGO, opcCodigo);
    }
    checkIfWasLogged(hash, opcCodigo);
  }, [searchParams, checkIfWasLogged, set]);

  const isAuthenticated = Boolean(profile?.nomeUsuario);

  const doctor = get<MedicoDermato>(StorageKeys.MEDICAL);

  const isAuthenticatedDoctor = Boolean(doctor?.medicoId);

  return {
    isAuthenticated,
    isAuthenticatedDoctor,
    profile,
    loading,
    doctor,
  };
};
