import { useLocation } from 'react-router-dom';

import { Box, Button, Divider, Typography } from '@mui/material';
import { SearchTable } from 'modules/MedicalExam/fragments/SearchExam/search-table.type';
import { StyledControlAccess } from 'modules/MedicalExam/MedicalExam.styles';
import { Breadcrumbs } from 'ui/components';

import { AddressDataTable } from './SingleExam/AddressDataTable';
import { ExamHistoryDataTable } from './SingleExam/ExamHistoryDataTable';
import { PersonalDataTable } from './SingleExam/PersonalDataTable';

export const SingleExamReport = () => {
  const { state } = useLocation();

  const accredited = state as SearchTable;

  return (
    <StyledControlAccess>
      <Breadcrumbs
        items={[
          { text: 'Página inicial', route: '/' },
          {
            text: 'Resultado da pesquisa',
            route: '/manager/report/exam/single',
          },
        ]}
      />
      <Typography
        color="black"
        fontSize="2em"
        fontWeight={600}
        textAlign="left"
      >
        {accredited.nome}
      </Typography>
      <Typography
        color="black"
        fontSize="1.5em"
        fontWeight={500}
        textAlign="left"
      >
        {accredited.categoria} - {accredited.credencial}
      </Typography>

      <PersonalDataTable accredited={accredited} />

      {/* <Divider sx={{ mt: 4 }} />

      <AddressDataTable
        contacts={accredited.contatos ?? [accredited?.contato]}
      /> */}

      <Divider sx={{ mt: 4 }} />

      <ExamHistoryDataTable accredited={accredited} />
      <Box display="flex" justifyContent="flex-end" mt={10}>
        <Button
          autoFocus
          className="button-block"
          color="primary"
          onClick={() => {
            window.location.href = '/';
          }}
          variant="contained"
        >
          VOLTAR
        </Button>
      </Box>
    </StyledControlAccess>
  );
};
