import { Box, Typography } from '@mui/material';
import { StyledControlAccess } from 'modules/MedicalExam/MedicalExam.styles';
import { Breadcrumbs } from 'ui/components';

import { FormEditDoctor } from './fragments/FormEditDoctor';

export const EditDoctor = () => (
  <StyledControlAccess>
    <Box margin="0 auto" maxWidth="800px">
      <Breadcrumbs
        items={[
          { text: 'Página inicial', route: '/' },
          {
            text: 'Editar cadastro médico',
            route: '/manager/doctor/edit',
          },
        ]}
      />
      <Typography
        color="text.primary"
        fontSize="2em"
        fontWeight={600}
        textAlign="left"
      >
        Editar cadastro médico
      </Typography>

      <FormEditDoctor />
    </Box>
  </StyledControlAccess>
);
