import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Grid, TextField } from '@mui/material';
import { client } from 'adapters/http';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import { StorageKeys, useStorage } from 'hooks/useStorage';
import { StyledControlAccess } from 'modules/MedicalExam/MedicalExam.styles';
import { Button, Modal } from 'ui/components';
import * as yup from 'yup';

interface ModalLoginMedicalProps {
  isOpen: boolean;
  close: () => void;
}

interface Inputs {
  cpf: string;
  senha: string;
}

interface ResponseLogin {
  medicoId: number;
  medicoNome: string;
  codRetorno: string;
  msgRetorno: string;
}

const validationSchema = yup
  .object()
  .shape({
    cpf: yup
      .string()
      .required('O CPF é necessário.')
      .test('valid-cpf', 'O CPF não é válido.', (value) => {
        if (value) {
          return cpfValidator.isValid(value);
        }
        return false;
      }),
    senha: yup.string().required('A senha é necessária.').min(4),
  })
  .required();

export const ModalLoginMedical: FC<ModalLoginMedicalProps> = ({
  isOpen,
  close,
}) => {
  const { set } = useStorage();

  const [hasError, setHasError] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
  });

  const values = getValues();

  const cacheResponse = (medical: ResponseLogin) => {
    set(StorageKeys.MEDICAL, medical);
  };

  const manageReturnLogin = (data: ResponseLogin) => {
    if (data.codRetorno === 'COD_SUCESSO') {
      cacheResponse(data);
      return close();
    }

    setHasError(true);
    return setErrorMessage(data.msgRetorno);
  };

  const onSubmit = async (form: typeof values) => {
    setHasError(false);
    setLoading(true);

    if (form.senha) {
      const cpf = form.cpf.replace(/\D/g, '');

      const { senha } = form;

      const payload = { cpf, senha };

      client
        .post<ResponseLogin>('/dermato/login', payload)
        .then(({ data }) => manageReturnLogin(data))
        .finally(() => setLoading(false));
    }
  };

  const hasCPFErros = Boolean(errors.cpf);

  const hasSenhaErros = Boolean(errors.senha);

  const { onBlur, pattern, required, onChange, name } = register('cpf');

  return (
    <StyledControlAccess>
      <Modal
        disableEscapeKeyDown
        handleClose={close}
        open={isOpen}
        title="Login do médico"
      >
        <Box
          autoComplete="off"
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          {hasError && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {errorMessage}
            </Alert>
          )}

          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ReactInputMask
                mask="999.999.999-99"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                pattern={pattern}
                required={required}
              >
                <TextField
                  autoComplete="off"
                  autoFocus
                  error={hasCPFErros}
                  fullWidth
                  {...register('cpf')}
                  helperText={hasCPFErros && errors.cpf?.message}
                  label="CPF"
                  placeholder="CPF"
                  type="tel"
                  variant="outlined"
                />
              </ReactInputMask>
            </Grid>
            <Grid item>
              <TextField
                autoComplete="off"
                error={hasSenhaErros}
                fullWidth
                helperText={hasSenhaErros && errors.senha?.message}
                label="Senha"
                placeholder="Senha"
                type="password"
                {...register('senha')}
                variant="outlined"
              />
            </Grid>
            <Grid display="flex" item justifyContent="flex-end">
              <Button
                className="button-block"
                color="primary"
                disabled={loading}
                type="submit"
                variant="contained"
              >
                Entrar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </StyledControlAccess>
  );
};
