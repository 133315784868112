import React, { createContext, useContext, useMemo, useState } from 'react';

export type UnitAccessContent = {
  isLoadingPage: boolean;
  setIsLoadingPage: (a: boolean) => void;
};

export const UnitAccessContext = createContext<UnitAccessContent>({
  isLoadingPage: false,
  setIsLoadingPage: () => {},
});

export const UnitAccessContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoadingPage, setIsLoadingPage] = useState(false);

  const contextValue = useMemo(
    () => ({
      isLoadingPage,
      setIsLoadingPage,
    }),
    [isLoadingPage]
  );

  return (
    <UnitAccessContext.Provider value={contextValue}>
      {children}
    </UnitAccessContext.Provider>
  );
};

export const useUnitAccess = () => useContext(UnitAccessContext);
