import React, { createContext, useContext, useMemo, useState } from 'react';

export type MedicalExamContent = {
  isLoadingPage: boolean;
  setIsLoadingPage: (a: boolean) => void;
};

export const MedicalExamContext = createContext<MedicalExamContent>({
  isLoadingPage: false,
  setIsLoadingPage: () => {},
});

export const MedicalExamContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoadingPage, setIsLoadingPage] = useState(false);

  const contextValue = useMemo(
    () => ({
      isLoadingPage,
      setIsLoadingPage,
    }),
    [isLoadingPage]
  );

  return (
    <MedicalExamContext.Provider value={contextValue}>
      {children}
    </MedicalExamContext.Provider>
  );
};

export const useMedicalExam = () => useContext(MedicalExamContext);
