import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, TextField, Typography } from '@mui/material';

interface HeaderTableProps {
  total: number;
  search: (key: string) => void;
}

export const HeaderTable: FC<HeaderTableProps> = ({ total, search }) => {
  const navigate = useNavigate();

  const handleSerch = (key: string) => {
    search(key);
  };

  return (
    <Box
      alignItems="flex-end"
      display="flex"
      justifyContent="space-between"
      marginBottom={4}
    >
      <Typography color="text.primary" marginTop={4} textAlign="left">
        Total: {total} registros
      </Typography>

      <Box alignItems="flex-end" display="flex" flexDirection="column">
        <Button
          color="primary"
          disableElevation
          onClick={() => navigate('/manager/doctor/new')}
          sx={{ height: '50px', paddingX: 10, mb: 2 }}
          type="submit"
          variant="contained"
        >
          NOVO CADASTRO
        </Button>
        {/* <Box
          alignItems="center"
          display="flex"
          gap={4}
          justifyContent="space-between"
        >
          <Typography color="text.primary" textAlign="left">
            Pesquisar
          </Typography>
          <TextField
            label=""
            onChange={(e) => handleSerch(e.target.value)}
            size="small"
            variant="outlined"
          />
        </Box> */}
      </Box>
    </Box>
  );
};
