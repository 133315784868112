import { useNavigate } from 'react-router-dom';

import { Button, Grid, Typography } from '@mui/material';
import { useSession } from 'context/AuthProvider';
import { Header, Loading } from 'ui/components';

export const Links = () => {
  const { loading } = useSession();

  const navigate = useNavigate();

  const handleOpenSystem = () => {
    navigate('/');
  };

  return (
    <Loading active={loading}>
      <Header notMenuInternal pages={['APOIO', 'INTERVALO', 'SISTEMAS']} />
      <Grid maxWidth="sm" sx={{ margin: '20px 50px 10px' }}>
        <Typography fontWeight={500} style={{ marginBottom: 20 }} variant="h4">
          SISTEMAS
        </Typography>
        <Button onClick={handleOpenSystem} variant="text">
          Exame médico dermatológico
        </Button>
      </Grid>
    </Loading>
  );
};
