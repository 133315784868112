import React, { createContext, useContext, useMemo, useState } from 'react';

export type ManagerDoctorsContent = {
  isLoadingPage: boolean;
  setIsLoadingPage: (a: boolean) => void;
};

export const ManagerDoctorsContext = createContext<ManagerDoctorsContent>({
  isLoadingPage: false,
  setIsLoadingPage: () => {},
});

export const ManagerDoctorsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoadingPage, setIsLoadingPage] = useState(false);

  const contextValue = useMemo(
    () => ({
      isLoadingPage,
      setIsLoadingPage,
    }),
    [isLoadingPage]
  );

  return (
    <ManagerDoctorsContext.Provider value={contextValue}>
      {children}
    </ManagerDoctorsContext.Provider>
  );
};

export const useManagerDoctors = () => useContext(ManagerDoctorsContext);
