/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { client } from 'adapters/http';
import { format } from 'date-fns';
import { formatDate } from 'helpers/date';
import { renameDoctor } from 'helpers/rename';
import { StorageKeys, useStorage } from 'hooks/useStorage';
import { ProfileDermato } from 'models/profile.model';
import { SearchTable } from 'modules/MedicalExam/fragments/SearchExam/search-table.type';
import { StyledControlAccess } from 'modules/MedicalExam/MedicalExam.styles';
import { Breadcrumbs, Loading } from 'ui/components';

import { HeaderReport } from './fragments/HeaderReport';

const StyledTable = styled(Table)({
  '& .MuiTableCell-root': {
    border: '1px solid black',
  },
});

const RedTableHead = styled(TableHead)({
  backgroundColor: '#50687a',
  '& .MuiTableCell-root': {
    color: 'white',
  },
});

interface Filter {
  dataIni?: string;
  dataFin?: string;
}

export const ExamReport = () => {
  const [situation, setSituation] = useState('');
  const [searchText, setSearchText] = useState('');
  const [exams, setExams] = useState<SearchTable[]>();
  const [allExams, setAllExams] = useState<SearchTable[]>();
  const { get } = useStorage();

  const [filter, setFilter] = useState<Filter>();
  const [defaultFilter, setDefaultFilter] = useState<any>({
    haveDefaultValue: null,
  });

  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const getExams = () => {
    setLoading(true);
    const currentProfile = get<ProfileDermato>(StorageKeys.MANAGER);
    const params = {
      uo: currentProfile?.codigoUnidade,
      dataIni: filter?.dataIni || null,
      dataFin: filter?.dataFin || null,
    };

    client
      .get<SearchTable[]>('/dermato/pesquisaExames', { params })
      .then(({ data }) => {
        setAllExams(data);
        setExams(data);
        window.sessionStorage.setItem('exams', JSON.stringify(data));
      })
      .catch((e) => {
        if (filter) {
          setAllExams([]);
          setExams([]);
          window.sessionStorage.removeItem('exams');
          window.sessionStorage.removeItem('filter');
        }
      })
      .finally(() => setLoading(false));
  };
  const filterDoctors = (value: string) =>
    allExams?.filter((item) => {
      const doctorValue = item.nome as string;
      if (typeof doctorValue === 'string') {
        return doctorValue.toLowerCase().includes(value.toLowerCase());
      }
      return false;
    });

  const handleSearch = (key: string) => {
    setSearchText(key);
    if (!key) getExams();

    const results = filterDoctors(key);

    setExams(results);
  };

  useEffect(() => {
    getExams();
  }, [filter, searchParams]);
  useEffect(() => {
    if (searchText?.length > 0 && situation?.length > 0) {
      setExams(
        filterDoctors(searchText)?.filter(
          (item: any) => item?.exameRecente?.situacaoRecente === situation
        )
      );
    } else if (situation?.length > 0) {
      setExams(
        allExams?.filter(
          (item: any) => item?.exameRecente?.situacaoRecente === situation
        )
      );
    }
  }, [situation, searchText, allExams]);
  const parseJSON = (object: string) => {
    try {
      const obj = JSON.parse(object);
      return obj;
    } catch (e) {
      return false;
    }
  };

  useLayoutEffect(() => {
    const examsStorage: any = window.sessionStorage.getItem('exams');
    const filterStorage: any = window.sessionStorage.getItem('filter');
    const examsObject = parseJSON(examsStorage);
    const filterObject = parseJSON(filterStorage);
    if (examsObject) {
      setAllExams(examsObject);
      setExams(examsObject);
    }
    if (filterObject) {
      setDefaultFilter({ ...filterObject, haveDefaultValue: true });
    } else {
      setDefaultFilter({ haveDefaultValue: false });
    }
  }, []);

  const total = exams?.length ?? 0;
  return (
    <Loading active={loading}>
      <StyledControlAccess>
        <Breadcrumbs items={[{ text: 'Página inicial', route: '/' }]} />
        <Typography
          color="text.primary"
          fontSize="2em"
          fontWeight={600}
          textAlign="left"
        >
          Relatório de exames realizados
        </Typography>
        {defaultFilter?.haveDefaultValue !== null && (
          <HeaderReport
            defaultValue={defaultFilter}
            filter={setFilter}
            search={handleSearch}
            setSituation={setSituation}
            situation={situation}
            total={total}
          />
        )}

        <StyledTable size="small">
          <RedTableHead>
            <TableRow>
              <TableCell align="center">NOME</TableCell>
              <TableCell align="center">CATEGORIA</TableCell>
              <TableCell align="center">CREDENCIAL</TableCell>
              <TableCell align="center">SITUAÇÃO</TableCell>
              <TableCell align="center">VALIDADE</TableCell>
              <TableCell align="center">REALIZAÇÃO DO EXAME</TableCell>
            </TableRow>
          </RedTableHead>
          <TableBody>
            {exams?.map?.((exam, index) => (
              <TableRow key={`${exam.categoria}-${index}`}>
                <TableCell align="center">
                  <Link state={exam} to="/manager/report/exam/single">
                    {exam.nome}
                  </Link>
                </TableCell>
                <TableCell align="center">{exam.categoria}</TableCell>
                <TableCell align="center">{exam.credencial}</TableCell>
                <TableCell align="center">
                  {exam.exameRecente.situacaoRecente}
                </TableCell>
                <TableCell align="center">
                  {formatDate(exam?.exameRecente?.validade)}
                </TableCell>
                <TableCell align="center">
                  {renameDoctor(exam?.exameRecente?.medico?.nome)}
                  <br />
                  {formatDate(exam?.exameRecente?.dataRealizacao)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledControlAccess>
    </Loading>
  );
};
