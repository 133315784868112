import { Manager } from 'modules';
import { ManagerDoctorsContextProvider } from 'modules/Doctors/context/ManagerDoctors.context';
import { Header } from 'ui/components';

export const NewDoctor = () => (
  <ManagerDoctorsContextProvider>
    <Manager type="new">
      <Header label="Exame Dermatológico" />
    </Manager>
  </ManagerDoctorsContextProvider>
);
