import React from 'react';

import { MedicalExam } from 'modules';
import { MedicalExamContextProvider } from 'modules/MedicalExam/context/MedicalExam.context';
import { Header } from 'ui/components';

export const Single = (): JSX.Element => (
  <MedicalExamContextProvider>
    <MedicalExam type="single">
      <Header label="Exame Dermatológico" />
    </MedicalExam>
  </MedicalExamContextProvider>
);
