import { FC } from 'react';
import { Link } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatDate } from 'helpers/date';

import { ResponseCredential } from '../../models/responseCredentials.type.';

export const TableInfoPatient: FC<{
  credentials: ResponseCredential[] | undefined;
  currentCredential?: number;
}> = ({ credentials, currentCredential }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nome</TableCell>
          <TableCell align="left">Idade</TableCell>
          <TableCell align="left">Data de nascimento</TableCell>
          <TableCell align="left">Gênero</TableCell>
          <TableCell align="left">Data</TableCell>
          <TableCell align="left">Status</TableCell>
          <TableCell align="left">Unidade</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {credentials?.map((row) => (
          <TableRow key={row.nome} sx={{ 'td, th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Link
                state={row}
                to={`/manager/exams/single/${currentCredential}`}
              >
                {row.nome}
              </Link>
            </TableCell>

            <TableCell align="left">{row.idade} anos</TableCell>
            <TableCell align="left">
              {formatDate(row?.dataNascimento)}
            </TableCell>
            <TableCell align="left">{row.genero}</TableCell>
            <TableCell align="left">{formatDate(row?.data)}</TableCell>
            <TableCell align="left">{row?.status}</TableCell>
            <TableCell align="left">{row?.unidade}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

TableInfoPatient.defaultProps = {
  currentCredential: undefined,
};
