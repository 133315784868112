import { FC } from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import { formatDate } from 'helpers/date';
import { renameDoctor } from 'helpers/rename';
import { SearchTable } from 'modules/MedicalExam/fragments/SearchExam/search-table.type';

const TableStyled = styled(Table)({
  '& .MuiTableCell-root': {},
  '& .MuiTableHead-root .MuiTableCell-root': {},
});

interface ExamHistoryDataTableProps {
  accredited: SearchTable;
}

export const ExamHistoryDataTable: FC<ExamHistoryDataTableProps> = ({
  accredited,
}) => {
  const history = accredited?.historicoExames ?? [];

  return (
    <Box>
      <Typography
        color="black"
        fontSize="1.5em"
        fontWeight={500}
        marginTop={5}
        textAlign="left"
      >
        Histórico de exames
      </Typography>
      <TableStyled>
        <TableHead>
          <TableRow>
            <TableCell align="left">Data da realização</TableCell>
            <TableCell align="left">Médico responsável</TableCell>
            <TableCell align="left">Situação</TableCell>
            <TableCell align="left">Validade</TableCell>
            <TableCell align="left">Observação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history?.map?.((data, index: number) => (
            <TableRow key={data?.exameId ?? index}>
              <TableCell align="left">
                {formatDate(data?.dataRealizacao)}
              </TableCell>
              <TableCell align="left">
                {renameDoctor(data?.medico?.nome)}, CRM {data?.medico?.crm} -{' '}
                {data?.unidadeRealizacao}
              </TableCell>
              <TableCell align="left">{data?.situacao}</TableCell>
              <TableCell align="left">{formatDate(data?.validade)}</TableCell>
              <TableCell align="left">{data?.observacao}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableStyled>
    </Box>
  );
};
