import { useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { Search as SearchIcon } from 'ui/components/Icon';

import { Search, SearchIconWrapper, StyledInputBase } from './search.styles';

const SearchHeader = () => {
  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  const handleSearch = () => {
    if (!search) return;
    // window.history.pushState(
    //   {
    //     previousRoute: window.location.pathname,
    //   },
    //   'undefined',
    //   `/search?s=${search}`
    // );
    //    navigate(`/search&s=${search}`);
    window.location.href = `/?s=${search}`;
    //  navigate(`/?path=search&s=${search}`);
    // if (!window.location.pathname?.includes?.('search')) {
    //   navigate(`/search?s=${search}`);
    // } else {
    //   window.history.back();
    //   redirect(`/search?s=${search}`);
    // }
  };

  return (
    <Box sx={{ flexGrow: 0, marginX: 5 }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <form action="/" onSubmit={handleSearch}>
          <StyledInputBase
            inputProps={{ 'aria-label': 'text' }}
            name="s"
            onChange={(e) => {
              e.preventDefault();
              setSearch(e.target.value);
            }}
            placeholder="Digite o nº da credencial ou CPF e tecle ENTER"
            value={search}
          />
        </form>
      </Search>
    </Box>
  );
};
export default SearchHeader;
