import { FC } from 'react';

import { Box } from '@mui/material';

import { EditDoctor } from './EditDoctor';
import { ExamReport } from './ExamReport';
import { SingleExamReport } from './fragments/SingleExamReport';
import { NewDoctor } from './NewDoctor';
import { TableDoctors } from './TableDoctors';

type Types = 'list' | 'new' | 'edit' | 'report' | 'single';

export const Manager: FC<{ type: Types }> = ({ children, type }) => (
  <Box>
    {children}
    <Box sx={{ flexGrow: 1, py: 4, px: 8 }}>
      {type === 'report' && <ExamReport />}
      {type === 'list' && <TableDoctors />}
      {type === 'new' && <NewDoctor />}
      {type === 'edit' && <EditDoctor />}
      {type === 'single' && <SingleExamReport />}
    </Box>
  </Box>
);
