import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { client } from 'adapters/http';
import * as yup from 'yup';

import { Doctor } from '../models/docktor';

interface ResetPasswordProps {
  close: () => void;
  doctor: Doctor;
}

interface Inputs {
  senha: string;
  novaSenha: string;
}

interface ResponseLogin {
  medicoId: number;
  medicoNome: string;
  codRetorno: string;
  msgRetorno: string;
}

const validationSchema = yup
  .object()
  .shape({
    senha: yup.string().required('A senha é necessária.').min(4),
    novaSenha: yup
      .string()
      .required('A repetição da senha é necessária.')
      .min(4)
      .oneOf([yup.ref('senha')], 'As senhas devem ser iguais.'),
  })
  .required();

export const ResetPassword: FC<ResetPasswordProps> = ({ close, doctor }) => {
  const [hasError, setHasError] = useState(false);

  const [loading, setLoading] = useState(false);

  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
  });

  const values = getValues();

  const onSubmit = async (form: typeof values) => {
    setHasError(false);
    setLoading(true);

    if (form.senha) {
      const { novaSenha } = form;
      const { id } = doctor;

      const payload = { novaSenha, medicoId: id };

      client
        .patch<ResponseLogin>('/dermato/redefinirSenha', payload)
        .then(() => close())
        .catch(() => setHasError(true))
        .finally(() => setLoading(false));
    }
  };

  const hasSenhaErros = Boolean(errors.senha);

  const hasNovaSenhaErros = Boolean(errors.novaSenha);

  return (
    <Box
      autoComplete="off"
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      {hasError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          Não foi possivel alterar a senha, tente novamente mais tarde!
        </Alert>
      )}

      <Typography color="text.primary" marginTop={4} textAlign="left">
        Nome: {doctor.nome}
      </Typography>

      <Typography color="text.primary" marginY={4} textAlign="left">
        CPF: {doctor.cpf}
      </Typography>

      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            autoComplete="off"
            autoFocus
            error={hasSenhaErros}
            fullWidth
            helperText={hasSenhaErros && errors.senha?.message}
            label="Nova senha"
            placeholder="Nova senha"
            type="password"
            {...register('senha')}
            variant="outlined"
          />
        </Grid>
        <Grid item marginY={4}>
          <TextField
            autoComplete="off"
            error={hasNovaSenhaErros}
            fullWidth
            helperText={hasNovaSenhaErros && errors.novaSenha?.message}
            label="Digite novamente a senha"
            placeholder="Digite novamente a senha"
            type="password"
            {...register('novaSenha')}
            variant="outlined"
          />
        </Grid>
        <Grid display="flex" gap={4} item justifyContent="flex-end">
          <Button
            className="button-block"
            color="secondary"
            disabled={loading}
            disableElevation
            onClick={close}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            className="button-block"
            color="primary"
            disabled={loading}
            disableElevation
            type="submit"
            variant="contained"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
