import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

import { theme } from 'ui/theme';

import { LoadingProps } from './Loading.types';

export const Loading = ({ active, children }: LoadingProps) => (
  <LoadingOverlay
    active={active}
    spinner
    styles={{
      content: (base) => ({
        ...base,
        color: theme.palette.primary.main,
      }),
      spinner: (base) => ({
        ...base,
        width: '100px',
        '& svg circle': {
          stroke: theme.palette.primary.main,
        },
      }),
      overlay: (base) => ({
        ...base,
        background: theme.palette.background.default,
      }),
    }}
    text="Carregando..."
  >
    {children}
  </LoadingOverlay>
);
