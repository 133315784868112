/* Reference
 * https://github.com/i18next/react-i18next/blob/master/example/locize/src/i18n.js
 */

import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import { resources } from './lang';

i18n.use(initReactI18next).init({
  lng: 'ptBr',
  ns: ['COMMONS', 'DASHBOARDS'],
  defaultNS: 'COMMONS',
  resources,
});

export default i18n;
