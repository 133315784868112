import { FC, MouseEvent, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  MenuItem,
  Typography,
} from '@mui/material';
import { AuthContext } from 'context/AuthProvider';
import { StorageKeys, useStorage } from 'hooks/useStorage';
import { ExitToApp } from 'ui/components/Icon';
import SearchHeader from 'ui/components/SearchHeader';
import { theme } from 'ui/theme';

import { StyledMenu } from './Menu.style';

interface MenuManagerlProps {
  label?: string;
}

export const MenuManager: FC<Partial<MenuManagerlProps>> = (props) => {
  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(
    null
  );

  const location = useLocation();

  const navigate = useNavigate();

  const openReport = Boolean(anchorElReport);

  const { label } = props;

  const { clear, get } = useStorage();

  const session = useContext(AuthContext);

  const { profile } = session;

  const handleLogout = () => {
    // const hash = get(StorageKeys.HASH);
    // const opcCodigo = get(StorageKeys.OPC_CODIGO);
    // `/?hash=${hash}&opc_codigo=${opcCodigo}`;
    clear();
    window.location.href = String(process.env.REACT_APP_INTRANET_SESC);
  };

  const handleToogleMenuReport = (event: MouseEvent<HTMLElement>) => {
    setAnchorElReport(event.currentTarget);
  };

  const handlecCloseMenuReport = () => {
    setAnchorElReport(null);
  };

  const handlecnavigate = (path: string) => {
    handlecCloseMenuReport();
    navigate(path);
  };

  const isActive = (route: string): 'text' | 'contained' => {
    const paths = location.pathname.split('/');
    const isIncludes = paths.includes(route) || location.pathname === route;
    return isIncludes ? 'contained' : 'text';
  };

  if (!profile) return null;

  return (
    <AppBar
      color="secondary"
      position="static"
      style={{
        marginBottom: '10px',
        padding: '0px 10px',
        boxShadow: 'none',
      }}
    >
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Link color="inherit" href="/" underline="none">
          <Typography fontSize={24}>{label || ''}</Typography>
        </Link>

        <Box alignItems="center" display="flex">
          <SearchHeader />
          <Box alignItems="center" display="flex" gap={2} marginRight={10}>
            <Button
              disableElevation
              endIcon={openReport ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              onClick={handleToogleMenuReport}
              sx={{ p: '20px', borderRadius: 0, color: 'white' }}
              variant={isActive('report')}
            >
              Relatório
            </Button>
            <Button
              disableElevation
              onClick={() => navigate('/manager/doctor/list')}
              sx={{ p: '20px', borderRadius: 0, color: 'white' }}
              variant={isActive('doctor')}
            >
              Senha do médico
            </Button>
            <Button
              disableElevation
              onClick={() => navigate('/?admin=true')}
              sx={{ p: '20px', borderRadius: 0, color: 'white' }}
              variant={isActive('/')}
            >
              Novo exame
            </Button>
          </Box>
          <StyledMenu
            anchorEl={anchorElReport}
            onClose={handlecCloseMenuReport}
            open={openReport}
          >
            <MenuItem
              disableRipple
              onClick={() => handlecnavigate('/manager/report/exam')}
            >
              Exames realizados
            </MenuItem>
            {/* <MenuItem
              disableRipple
              onClick={() => handlecnavigate('/manager/report/exam')}
            >
              Categorias e faixa etárias
            </MenuItem> */}
          </StyledMenu>

          <Box>
            <IconButton sx={{ p: 0, cursor: 'default' }}>
              <Avatar
                alt={profile.nomeUsuario.toUpperCase()}
                src="/"
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              />
            </IconButton>
            <IconButton
              onClick={handleLogout}
              sx={{
                marginRight: 1,
                color: theme.palette.primary.contrastText,
              }}
            >
              <ExitToApp fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};
