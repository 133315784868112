import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { Button } from 'ui/components';

export const StyledControlAccess = styled('div')(({ theme }) => ({
  color: theme?.palette?.primary?.main,
  backgroundColor: theme?.palette?.background?.main,
  borderRadius: theme?.shape?.borderRadius,
}));

export const HR = styled('hr')(({ theme }) => ({
  marginBottom: theme?.spacing(2),
  border: '1px solid white',
  borderTop: '1px solid #bcbcbc',
}));

export const ButtonAdd = styled(Button)(({ theme }) => ({
  marginBottom: theme?.spacing(2),
}));

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
