/* eslint-disable consistent-return */
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
  TextField,
} from '@mui/material';
import { client } from 'adapters/http';
import { Button, Radio } from 'ui/components';
import { theme } from 'ui/theme';
import * as yup from 'yup';

import { Condition, ExamType } from './condition.types';

interface ConditionBoxProps {
  cancelExam: (showBox: boolean) => void;
  saveExam: (exam: ExamType) => void;
  showBox: boolean;
  credential: any;
}

const validationSchema = yup
  .object()
  .shape({
    comment: yup.string(),
    condition: yup.object().shape({
      condicaoId: yup.number().required('Condição obrigatória'),
      condicaoNome: yup.string().required('Condição obrigatória'),
    }),
  })
  .required();

export const ConditionBox: FC<ConditionBoxProps> = (props) => {
  const { cancelExam, showBox, saveExam, credential } = props;

  const [conditions, setConditions] = useState<Condition[]>();

  const [currentCondition, setCurrentCondition] = useState<Condition>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ExamType>({
    resolver: yupResolver(validationSchema),
  });
  const commentText = watch('comment');
  useEffect(() => {
    if (commentText?.length > 201) {
      setValue('comment', commentText.substring(0, 201));
    }
  }, [commentText?.length]);
  useEffect(() => {
    if (commentText?.length > 0) {
      setValue('comment', '');
    }
  }, [credential]);
  const handleSetCondition = useCallback(
    (condt: Condition) => {
      setValue('condition', condt);
      setCurrentCondition(condt);
    },
    [setValue]
  );

  const getContitions = useCallback(() => {
    client.get<Condition[]>('/dermato/tiposCondicao').then(({ data }) => {
      if (data) {
        const firstCondition = data[0];
        setConditions(data);
        handleSetCondition(firstCondition);
      }
    });
  }, [handleSetCondition]);
  const hasCommentErrors = commentText?.length > 200;

  const handleSaveCondition = async (currentCodidition: ExamType) => {
    if (hasCommentErrors) {
      return;
    }
    const response = await saveExam(currentCodidition);
    setValue('comment', '');
    return response;
  };

  useEffect(() => {
    getContitions();
  }, [getContitions]);

  if (!showBox) return null;
  return (
    <>
      <form onSubmit={handleSubmit(handleSaveCondition)}>
        <Paper
          sx={{
            backgroundColor: theme.palette.background.default,
            paddingY: 2,
            paddingX: 4,
            textAlign: 'left',
            boxShadow: 'none',
          }}
        >
          <h3>CONDIÇÃO</h3>
          <RadioGroup defaultValue="apto" row sx={{ marginBottom: 2 }}>
            {conditions?.map((item) => (
              <FormControlLabel
                checked={item.condicaoId === currentCondition?.condicaoId}
                control={<Radio />}
                key={item.condicaoId}
                label={item.condicaoNome}
                onChange={() => handleSetCondition(item)}
                value={item.condicaoId}
              />
            ))}
          </RadioGroup>
          <TextField
            fullWidth
            label="Observação"
            multiline
            placeholder="Observação"
            type="text"
            variant="outlined"
            {...register('comment')}
            error={hasCommentErrors}
            sx={{ marginBottom: 2, bgcolor: '#fff' }}
          />
          {hasCommentErrors ? (
            <span
              style={{
                color: theme.palette.error.main,
                display: 'block',
                textAlign: 'left',
              }}
            >
              {errors?.comment?.message ?? 'No máximo 200 caracteres'}
            </span>
          ) : (
            <span
              style={{
                color: '#000',
                display: 'block',
                textAlign: 'left',
              }}
            >
              {`Restam ${200 - commentText?.length} caracteres`}
            </span>
          )}
        </Paper>
        <Grid container marginTop={2} spacing={2}>
          <Grid item marginLeft="auto">
            <Button
              className="button-block"
              color="secondary"
              fullWidth
              onClick={() => cancelExam(false)}
              type="button"
              variant="contained"
            >
              CANCELAR
            </Button>
          </Grid>
          <Grid item>
            <Button
              autoFocus
              className="button-block"
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              SALVAR
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
