import React, { useState } from 'react';

import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
} from '@mui/material';

import { SelectProps } from './Select.types';

export const Select = ({
  // handleChangeCallback,
  helperText,
  items,
  ...props
}: any) => {
  const handleChange = (event: SelectChangeEvent<any>) => {
    props.setValue(event.target.value);
    // handleChangeCallback();
  };

  return (
    <>
      <InputLabel id={props.id}>{props.label}</InputLabel>
      <MuiSelect
        {...props}
        label={props.label}
        onChange={handleChange}
        value={props.value || ''}
      >
        {items &&
          items.map((item: any) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </MuiSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
};
