import { Manager } from 'modules';
import { UnitAccessContextProvider } from 'modules/Manager/context/UnitAccess.context';
import { Header } from 'ui/components';

export const MedicalExamReport = (): JSX.Element => (
  <UnitAccessContextProvider>
    <Manager type="report">
      <Header label="Exame Dermatológico" pages={[]} />
    </Manager>
  </UnitAccessContextProvider>
);
