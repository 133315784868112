import { Manager } from 'modules';
import { ManagerDoctorsContextProvider } from 'modules/Doctors/context/ManagerDoctors.context';
import { Header } from 'ui/components';

export const EditDoctor = () => (
  <ManagerDoctorsContextProvider>
    <Manager type="edit">
      <Header label="Exame Dermatológico" />
    </Manager>
  </ManagerDoctorsContextProvider>
);
