import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useLocation, useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { client } from 'adapters/http';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import * as yup from 'yup';

interface Inputs {
  nome: string;
  cpf: string;
  crm: string;
  situacao: string;
}

interface ResponseLogin {
  medicoId: number;
  medicoNome: string;
  codRetorno: string;
  msgRetorno: string;
}

const validationSchema = yup
  .object()
  .shape({
    nome: yup.string().required('O nome é necessário.').min(4),
    cpf: yup
      .string()
      .required('O CPF é necessário.')
      .test('valid-cpf', 'O CPF não é válido.', (value) => {
        if (value) {
          return cpfValidator.isValid(value);
        }
        return false;
      }),
    crm: yup.string().required('O CRM é necessário.'),
    situacao: yup.string().required('Selecione uma situação.'),
  })
  .required();

export const FormEditDoctor: FC = () => {
  const [hasError, setHasError] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    getValues,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      nome: state?.nome ?? '',
      crm: state?.crm ?? '',
      cpf: state?.cpf ?? '',
      situacao: state?.situacao?.toLowerCase?.() ?? '',
    },
  });
  const values = getValues();

  const onSubmit = async (form: typeof values) => {
    setHasError(false);
    setLoading(true);

    const cpf = form.cpf.replace(/\D/g, '');

    const { nome, crm, situacao } = form;

    const payload = {
      nome,
      cpf,
      crm,
      situacao,
    };

    client
      .patch<ResponseLogin>(`/dermato/editaMedico/${state.id}`, payload)
      .then(() => navigate('/manager/doctor/list'))
      .catch(() => setHasError(true))
      .finally(() => setLoading(false));
  };

  const hasNomeErros = Boolean(errors.nome);

  const hasCPFErros = Boolean(errors.cpf);

  const hasCRMErros = Boolean(errors.crm);

  const { onBlur, pattern, required, onChange, name } = register('cpf');

  return (
    <Box
      autoComplete="off"
      component="form"
      margin="0 auto"
      maxWidth="800px"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      {hasError && (
        <Alert severity="error" sx={{ my: 3 }}>
          Não foi possivel editar o(a) médico(a), tente novamente mais tarde!
        </Alert>
      )}

      <Grid container direction="column" marginTop={10}>
        <Grid item marginY={2}>
          <TextField
            autoComplete="off"
            autoFocus
            error={hasNomeErros}
            fullWidth
            helperText={hasNomeErros && errors.nome?.message}
            label="Nome do médico(a)"
            placeholder="Nome completo"
            type="text"
            {...register('nome')}
            defaultValue={state?.nome ?? ''}
            variant="outlined"
          />
        </Grid>
        <Grid container direction="column" maxWidth="400px">
          <Grid item marginY={2}>
            <ReactInputMask
              mask="999.999.999-99"
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              pattern={pattern}
              required={required}
            >
              <TextField
                autoComplete="off"
                error={hasCPFErros}
                fullWidth
                {...register('cpf')}
                defaultValue={state?.cpf ?? ''}
                helperText={hasCPFErros && errors.cpf?.message}
                label="CPF"
                placeholder="CPF"
                type="tel"
                variant="outlined"
              />
            </ReactInputMask>
          </Grid>
          <Grid item marginY={2}>
            <TextField
              autoComplete="off"
              error={hasCRMErros}
              fullWidth
              helperText={hasCRMErros && errors.crm?.message}
              label="CRM"
              placeholder="CRM"
              type="text"
              {...register('crm')}
              defaultValue={state?.crm ?? ''}
              variant="outlined"
            />
          </Grid>

          <Grid item marginY={2}>
            <RadioGroup
              defaultValue={state?.situacao?.toLowerCase?.()}
              row
              sx={{ marginBottom: 2 }}
            >
              <Controller
                control={control}
                name="situacao"
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      control={<Radio {...field} value="ativo" />}
                      label="Ativo"
                    />
                    <FormControlLabel
                      control={<Radio {...field} value="inativo" />}
                      label="Inativo"
                    />
                  </>
                )}
              />
            </RadioGroup>
            <Box>
              {errors.situacao && (
                <Typography color="red" variant="body2">
                  {errors.situacao.message}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid
          display="flex"
          gap={4}
          item
          justifyContent="flex-start"
          marginTop={8}
        >
          <Button
            className="button-block"
            color="inherit"
            disabled={loading}
            disableElevation
            onClick={() => navigate('/manager/doctor/list')}
            sx={{ py: 4, px: 8 }}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            className="button-block"
            color="primary"
            disabled={loading}
            disableElevation
            sx={{ py: 4, px: 8 }}
            type="submit"
            variant="contained"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
