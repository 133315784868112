import { FC, useContext } from 'react';

import { AppBar, Avatar, Box, IconButton, Typography } from '@mui/material';
import { AuthContext } from 'context/AuthProvider';
import { StorageKeys, useStorage } from 'hooks/useStorage';
import { ExitToApp } from 'ui/components/Icon';
import SearchHeader from 'ui/components/SearchHeader';
import { theme } from 'ui/theme';

interface MenuMediucalProps {
  label?: string;
}

export const MenuMedical: FC<Partial<MenuMediucalProps>> = (props) => {
  const { label } = props;

  const { clear, get } = useStorage();

  const session = useContext(AuthContext);

  const { profile, doctor } = session;

  const handleLogout = () => {
    const hash = get(StorageKeys.HASH);
    const opcCodigo = get(StorageKeys.OPC_CODIGO);

    clear();
    window.location.href = `/?hash=${hash}&opc_codigo=${opcCodigo}`; // String(process.env.REACT_APP_INTRANET_SESC);
  };

  if (!profile) return null;

  return (
    <AppBar
      color="secondary"
      position="static"
      style={{
        marginBottom: '10px',
        padding: '0px 10px',
        boxShadow: 'none',
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        paddingY={2}
      >
        <Typography fontSize={24}>{label || ''}</Typography>

        <Box alignItems="center" display="flex">
          <SearchHeader />
          <Box>
            <IconButton sx={{ p: 0, cursor: 'default' }}>
              <Avatar
                alt={(doctor?.medicoNome ?? profile?.nomeUsuario).toUpperCase()}
                src="/"
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              />
              <Typography
                component="span"
                noWrap
                sx={{
                  ml: 1,
                  mr: 1,
                  display: { xs: 'none', md: 'flex' },
                  color: theme.palette.primary.contrastText,
                  textDecoration: 'none',
                  fontSize: 16,
                }}
                variant="h6"
              >
                {doctor?.medicoNome ?? profile?.nomeUsuario}
              </Typography>
            </IconButton>
            <IconButton
              onClick={handleLogout}
              sx={{
                marginRight: 1,
                color: theme.palette.primary.contrastText,
              }}
            >
              <ExitToApp fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};
