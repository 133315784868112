import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { client } from 'adapters/http';
import { format } from 'date-fns';
import { formatDate } from 'helpers/date';
import { StyledControlAccess } from 'modules/MedicalExam/MedicalExam.styles';
import { Breadcrumbs, Button, Loading } from 'ui/components';

import { SearchTable } from './search-table.type';

const StyledTable = styled(Table)({
  '& .MuiTableCell-root': {
    border: '1px solid black',
  },
});

const RedTableHead = styled(TableHead)({
  backgroundColor: '#50687a',
  '& .MuiTableCell-root': {
    color: 'white',
  },
});

export const SearchExam = () => {
  const [exams, setExams] = useState<SearchTable[]>();

  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const getExams = useCallback((search: string) => {
    setLoading(true);

    const identificacao = +search;

    const isNotANumber = Number.isNaN(identificacao);

    const isTypeofNumber = typeof identificacao === 'number';

    if (!isTypeofNumber || isNotANumber) {
      setLoading(false);
      return;
    }

    const params = {
      identificacao,
      dataIni: null,
      dataFin: null,
    };

    client
      .get<SearchTable[]>('/dermato/pesquisaExames', { params })
      .then(({ data }) => setExams(data))
      .finally(() => setLoading(false));
  }, []);

  const handleGoToDetail = (exam: SearchTable) => {
    navigate('/', { state: { credential: exam.credencial } });
  };

  useEffect(() => {
    const search = `${searchParams.get('s')}`;

    getExams(search);
  }, [getExams, searchParams]);

  return (
    <Loading active={loading}>
      <StyledControlAccess>
        <Breadcrumbs items={[{ text: 'Página inicial', route: '/' }]} />
        <Typography
          color="text.primary"
          fontSize="2em"
          fontWeight={600}
          textAlign="left"
        >
          Resultado da pesquisa
        </Typography>
        <Typography color="text.primary" marginTop={4} textAlign="left">
          Total: {exams?.length} registros
        </Typography>

        <StyledTable>
          <RedTableHead>
            <TableRow>
              <TableCell align="center">NOME</TableCell>
              <TableCell align="center">CATEGORIA</TableCell>
              <TableCell align="center">CREDENCIAL</TableCell>
              <TableCell align="center">SITUAÇÃO RECENTE</TableCell>
              <TableCell align="center">VALIDADE</TableCell>
              <TableCell align="center" />
            </TableRow>
          </RedTableHead>
          <TableBody>
            {exams?.map((exam) => (
              <TableRow key={exam.credencial}>
                <TableCell align="center">
                  <Link state={exam} to="/single">
                    {exam.nome}
                  </Link>
                </TableCell>
                <TableCell align="center">{exam.categoria}</TableCell>
                <TableCell align="center">{exam.credencial}</TableCell>
                <TableCell align="center">
                  {exam.exameRecente.situacaoRecente ?? 'Não consta'}
                </TableCell>
                <TableCell align="center">
                  {formatDate(exam?.exameRecente?.validade)}
                </TableCell>
                <TableCell align="center">
                  <Button
                    color="primary"
                    onClick={() => handleGoToDetail(exam)}
                    sx={{ height: '100%', paddingX: 10, mx: 4 }}
                    type="submit"
                    variant="contained"
                  >
                    REALIZAR EXAME
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledControlAccess>
    </Loading>
  );
};
