import { useContext } from 'react';

import { AppBar, Toolbar } from '@mui/material';
import { AuthContext } from 'context/AuthProvider';
import { Logo } from 'ui/theme';

import { MenuManager } from './fragments/MenuManager';
import { MenuMedical } from './fragments/MenuMedical';
import { HeaderProps } from './Header.types';

export const Header = (props: HeaderProps) => {
  const { label } = props;

  const session = useContext(AuthContext);

  const { profile } = session;

  const isManager = profile?.perfil === 'ADMINISTRADOR';

  if (!profile) return null;

  return (
    <>
      <AppBar position="static">
        <Toolbar disableGutters>
          <Logo height={40} width={120} />
        </Toolbar>
      </AppBar>
      {isManager ? (
        <MenuManager label={label} />
      ) : (
        <MenuMedical label={label} />
      )}
    </>
  );
};
