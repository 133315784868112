import { createContext, ReactNode, useContext, useMemo } from 'react';

import { ProfileDermato } from 'models/profile.model';

import { useAuth } from './useAuth';

interface Session {
  isAuthenticated: boolean;
  isAuthenticatedDoctor: boolean;
  loading: boolean;
  profile: ProfileDermato | null;
  doctor: any;
}

export const AuthContext = createContext<Session>({} as Session);

export const AuthMangerProvider = ({ children }: { children: ReactNode }) => {
  const session = useAuth();

  const value = useMemo(() => ({ ...session }), [session]);

  if (!session.profile) return null;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useSession = () => {
  const session = useContext(AuthContext);

  if (!session) {
    throw new Error('Cannot use `useSession` outside of SessionProvider');
  }
  return session;
};
