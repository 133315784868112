import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Grid, Paper, Snackbar, TextField } from '@mui/material';
// import api from 'helpers/api';
import { client } from 'adapters/http';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import { StorageKeys, useStorage } from 'hooks/useStorage';
import { ModalLoginMedical } from 'modules/Doctors/fragments/NewExam/fragments/ConditionBox/ModalLoginMedical';
import { MedicalExamContextProvider } from 'modules/MedicalExam/context/MedicalExam.context';
import { MedicalExam } from 'modules/MedicalExam/MedicalExam';
import { Button, Header, Loading } from 'ui/components';
import { Logo, theme } from 'ui/theme';
import * as yup from 'yup';

interface Inputs {
  cpf: string;
  senha: string;
}

interface ResponseLogin {
  medicoId: number;
  medicoNome: string;
  codRetorno: string;
  msgRetorno: string;
}

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

const validationSchema = yup
  .object()
  .shape({
    cpf: yup
      .string()
      .required('O CPF é necessário.')
      .test('valid-cpf', 'O CPF não é válido.', (value) => {
        if (value) {
          return cpfValidator.isValid(value);
        }
        return false;
      }),
    senha: yup.string().required('A senha é necessária.').min(4),
  })
  .required();

export const SignIn = () => {
  const navigate = useNavigate();

  const { set } = useStorage();

  const [hasError, setHasError] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [alertShow, setAlertShow] = useState(false);

  const [alertType, setAlertType] = useState<Severity>('success');

  const [alertText, setAlertText] = useState('');

  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
  });

  const values = getValues();

  const showAlertSnackbar = (type: Severity, text: string) => {
    setAlertType(type);
    setAlertText(text);
    setAlertShow(true);
  };

  const cacheResponse = (medical: ResponseLogin) => {
    set(StorageKeys.MEDICAL, medical);
  };

  const manageReturnLogin = (data: ResponseLogin) => {
    if (data.codRetorno === 'COD_SUCESSO') {
      showAlertSnackbar('success', 'Login efetuado com sucesso');
      cacheResponse(data);
      navigate('/');
      return;
    }

    setHasError(true);
    setErrorMessage(data.msgRetorno);
  };

  const onSubmit = async (form: typeof values) => {
    setHasError(false);
    setLoading(true);

    if (form.senha) {
      const cpf = form.cpf.replace(/\D/g, '');

      const { senha } = form;

      const payload = { cpf, senha };

      client
        .post<ResponseLogin>('/dermato/login', payload)
        .then(({ data }) => manageReturnLogin(data))
        .catch(() => {
          showAlertSnackbar('error', 'Erro ao logar com as credenciais');
        })
        .finally(() => setLoading(false));
    }
  };

  const hasCPFErros = Boolean(errors.cpf);

  const hasSenhaErros = Boolean(errors.senha);

  const { onBlur, pattern, required, onChange, name } = register('cpf');
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Loading active={false}>
      {/* <Grid maxWidth="sm" sx={{ width: '60%', margin: '80px auto 10px' }}>
        <Grid item sx={{ textAlign: 'center', marginBottom: 5 }}>
          <Logo
            fill={theme.palette.primary.main}
            fillRule="nonzero"
            height={80}
          />
        </Grid>
        <Paper
          className="login-background"
          elevation={2}
          sx={{ padding: 10 }}
          variant="elevation"
        >
          <Grid item sx={{ textAlign: 'center' }}>
            <h2 style={{ color: theme.palette.primary.main, marginTop: 0 }}>
              Login do médico
            </h2>
          </Grid>
          <Grid item>
            <Box
              autoComplete="off"
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              {hasError && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {errorMessage}
                </Alert>
              )}

              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <ReactInputMask
                    mask="999.999.999-99"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    pattern={pattern}
                    required={required}
                  >
                    <TextField
                      autoComplete="off"
                      autoFocus
                      error={hasCPFErros}
                      fullWidth
                      {...register('cpf')}
                      helperText={hasCPFErros && errors.cpf?.message}
                      label="CPF"
                      placeholder="CPF"
                      type="tel"
                      variant="outlined"
                    />
                  </ReactInputMask>
                </Grid>
                <Grid item>
                  <TextField
                    autoComplete="off"
                    error={hasSenhaErros}
                    fullWidth
                    helperText={hasSenhaErros && errors.senha?.message}
                    label="Senha"
                    placeholder="Senha"
                    type="password"
                    {...register('senha')}
                    variant="outlined"
                  />
                </Grid>
                <Grid display="flex" item justifyContent="flex-end">
                  <Button
                    className="button-block"
                    color="primary"
                    disabled={loading}
                    type="submit"
                    variant="contained"
                  >
                    Entrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Paper>
      </Grid> */}
      <MedicalExamContextProvider>
        <MedicalExam type="newexam">
          <Header label="Exame Dermatológico" />
        </MedicalExam>
      </MedicalExamContextProvider>
      <ModalLoginMedical
        close={() => {
          showAlertSnackbar('success', 'Login efetuado com sucesso');
          setIsOpen(false);
          navigate('/');
        }}
        isOpen={isOpen}
      />
      <Snackbar
        autoHideDuration={6000}
        onClose={() => setAlertShow(false)}
        open={alertShow}
      >
        <Alert
          onClose={() => setAlertShow(false)}
          severity={alertType || 'success'}
          sx={{ width: '100%' }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </Loading>
  );
};
