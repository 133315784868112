import { parseISO } from 'date-fns';

export function formatDate(date: Date | string): string {
  if (!date) {
    return '';
  }
  // Crie um objeto de data a partir da data original
  const data = parseISO(date as string);

  // Obtenha o dia, mês e ano da data
  const dia = data.getDate() > 9 ? data.getDate() : `0${data.getDate()}`;
  const mes =
    data.getMonth() + 1 > 9 ? data.getMonth() + 1 : `0${data.getMonth() + 1}`;
  const ano = data.getFullYear();

  const dataFormatada = `${dia}/${mes}/${ano}`;
  return dataFormatada;
}
