import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Alert, Snackbar } from '@mui/material';
import { client } from 'adapters/http';
import { StorageKeys, useStorage } from 'hooks/useStorage';
import { MedicoDermato } from 'models/medical.model';
import { ProfileDermato } from 'models/profile.model';
import { StyledControlAccess } from 'modules/MedicalExam/MedicalExam.styles';
import { Breadcrumbs } from 'ui/components';

import { ExamType } from './fragments/ConditionBox/condition.types';
import { ConditionBox } from './fragments/ConditionBox/ConditionBox';
import { SearchCredential } from './fragments/SearchCredential';
import { TableInfoPatient } from './fragments/TableInfoPatient';
import { ResponseCredential } from './models/responseCredentials.type.';

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

export const NewExam = () => {
  const location = useLocation();

  const searchRef = useRef<{ clearSearch: () => void }>(null);

  const { get } = useStorage();

  const [alertShow, setAlertShow] = useState(false);

  const [currentCredential, setCredential] = useState<number>(0);

  const [loading, setLoading] = useState(false);

  const [dataCredential, setDataCredential] = useState<ResponseCredential>();

  const [credentials, setCredentials] = useState<ResponseCredential[]>();

  const [alertType, setAlertType] = useState<Severity>('success');

  const [alertText, setAlertText] = useState('');

  const [showBox, setShowBox] = useState(false);

  const handleCloseExam = () => {
    setCredentials(undefined);
    setShowBox(false);
    searchRef?.current?.clearSearch();
  };

  const handleShowError = (message: string, type: Severity) => {
    setAlertType(type);
    setAlertText(message);
    setAlertShow(true);
  };

  const handleSearchCredential = useCallback(async (credential: number) => {
    setCredential(credential);
    setLoading(true);
    client
      .get<ResponseCredential>(`dermato/busca/${credential}`)
      .then(({ data }) => {
        if (data) {
          setShowBox(true);
          return setDataCredential(data);
        }
        const message =
          'Credencial não encontrada. Verifique e tente novamente.';
        const type = 'error';
        handleCloseExam();
        return handleShowError(message, type);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSaveExam = async (exam: ExamType) => {
    const { condition, comment } = exam;

    const medical = get<MedicoDermato>(StorageKeys.MEDICAL);

    const manager = get<ProfileDermato>(StorageKeys.MANAGER);

    const payload = {
      credencial: currentCredential,
      medicoId: medical?.medicoId ?? null,
      condicaoId: condition.condicaoId,
      observacao: comment,
      codigoUnidade: manager.codigoUnidade,
      nomeUsuario: dataCredential?.nome,
    };

    client
      .post<any>('/dermato/insereExame', payload)
      .then(() => {
        handleCloseExam();
        const message = 'Exame realizado com sucesso.';
        const type = 'success';

        handleShowError(message, type);
      })
      .finally(() => setLoading(false))
      .catch(() => {
        const message = 'Erro ao realizar exame.';
        const type = 'error';

        return handleShowError(message, type);
      });
  };

  useEffect(() => {
    if (dataCredential) {
      setCredentials([dataCredential]);
    }
  }, [dataCredential]);

  useEffect(() => {
    if (location.state) {
      const credential = +location.state.credential;

      setCredential(credential);

      handleSearchCredential(credential);
    }
  }, [handleSearchCredential, location, location.state]);

  return (
    <StyledControlAccess>
      <Breadcrumbs items={[{ text: 'Página inicial', route: '/' }]} />
      <h1 style={{ textAlign: 'left', color: 'black' }}>
        Novo exame dermatológico
      </h1>
      <SearchCredential
        loading={loading}
        ref={searchRef}
        submit={handleSearchCredential}
      />
      {showBox && (
        <TableInfoPatient
          credentials={credentials}
          currentCredential={currentCredential}
        />
      )}

      <ConditionBox
        cancelExam={handleCloseExam}
        credential={currentCredential}
        saveExam={handleSaveExam}
        showBox={showBox}
      />

      <Snackbar
        autoHideDuration={6000}
        onClose={() => setAlertShow(false)}
        open={alertShow}
      >
        <Alert
          onClose={() => setAlertShow(false)}
          severity={alertType || 'success'}
          sx={{ width: '100%' }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </StyledControlAccess>
  );
};
