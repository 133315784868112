import { FC } from 'react';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { NewExam } from 'modules/Doctors/fragments';
import { Loading } from 'ui/components';

import { useMedicalExam } from './context/MedicalExam.context';
import { SearchExam, SingleExam } from './fragments';
import { Item } from './MedicalExam.styles';

export const MedicalExam: FC<{ type: 'newexam' | 'search' | 'single' }> = ({
  children,
  type,
}) => {
  const { isLoadingPage } = useMedicalExam();

  return (
    <Loading active={isLoadingPage}>
      {children}
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item md={12} paddingBottom={10} xs={12}>
            <Item>
              {type === 'newexam' && <NewExam />}
              {type === 'search' && <SearchExam />}
              {type === 'single' && <SingleExam />}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Loading>
  );
};
