import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import { Button as MuiButton } from '@mui/material';

import { ButtonProps } from './Button.types';

export const Button = ({ children, ...props }: ButtonProps) => {
  if (props.loading) {
    return (
      <MuiLoadingButton data-testid="button" {...props}>
        {children}
      </MuiLoadingButton>
    );
  }
  return (
    <MuiButton data-testid="button" {...props}>
      {children}
    </MuiButton>
  );
};
