import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { Loading } from 'ui/components';

export const NotFound = () => {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => setIsLoadingPage(false), 2000);
  }, []);

  return (
    <Loading active={isLoadingPage}>
      <Box>
        <Alert severity="error" sx={{ width: '60%', margin: '10px auto' }}>
          <AlertTitle>Erro 404 - {location.pathname}</AlertTitle>
          <strong>Página não encontrada</strong>
        </Alert>
      </Box>
    </Loading>
  );
};
