import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import { format, parse } from 'date-fns';
import { Select } from 'ui/components';
import * as yup from 'yup';

interface Inputs {
  dataIni: string;
  dataFin: string;
}

interface HeaderReportProps {
  total: number;
  search: (key: string) => void;
  filter: (key: Inputs) => void;
  defaultValue: any;
  situation: any;
  setSituation: any;
}

const parseDate = (dateStr: string) => {
  const [day, month, year] = dateStr.split('/').map(Number);
  return new Date(year, month - 1, day);
};

const validationSchema = yup
  .object()
  .shape({
    dataIni: yup
      .string()
      .required('Data inicial obrigatória')
      .test('data-ini-valid', 'Data inicial inválida.', (value) => {
        const date = parseDate(value || '');
        return !Number.isNaN(date.getTime());
      })
      .test('data-ini', 'Data inicial inválida.', (value) => {
        const date = parseDate(value || '');
        return date && date <= new Date();
      }),
    dataFin: yup
      .string()
      .required('Data final obrigatória')
      .test('data-fim-valid', 'Data final inválida', (value) => {
        const date = parseDate(value || '');
        return !Number.isNaN(date.getTime());
      })
      .test('data-fim', 'Data final inválida', (value) => {
        const date = parseDate(value || '');
        return !Number.isNaN(date.getTime());
      })
      .test(
        'data-fim-after-data-ini',
        'Data final inválida',
        // eslint-disable-next-line func-names
        function (value) {
          const dateFim = parseDate(value || '');
          const dateIni = parseDate(this.parent.dataIni || '');
          return !dateIni || (dateFim && dateIni && dateFim >= dateIni);
        }
      ),
  })
  .required();

export const HeaderReport: FC<HeaderReportProps> = ({
  total,
  search,
  filter,
  defaultValue,
  situation,
  setSituation,
}) => {
  const convertBackDate = (date: string) => {
    try {
      const dataParsed = parse(date, 'yyyy-MM-dd', new Date());
      const dataFormatted = format(dataParsed, 'dd/MM/yyyy');
      return dataFormatted;
    } catch (e) {
      return '';
    }
  };
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    trigger,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dataIni: convertBackDate(defaultValue?.dataIni),
      dataFin: convertBackDate(defaultValue?.dataFin),
    },
  });

  const dataIniWatched = watch('dataIni');
  const dataFinWatched = watch('dataFin');
  useEffect(() => {
    if (dataIniWatched?.length > 0) {
      trigger('dataIni');
    }
  }, [dataIniWatched]);
  useEffect(() => {
    if (dataFinWatched?.length > 0) {
      trigger('dataFin');
    }
  }, [dataFinWatched]);
  const convertDate = (date: string) => {
    const dataParsed = parse(date, 'dd/MM/yyyy', new Date());

    return format(dataParsed, 'yyyy-MM-dd');
  };

  const onSubmit = async (form: Inputs) => {
    if (form.dataIni && form.dataFin) {
      const dataIni = convertDate(form.dataIni);

      const dataFin = convertDate(form.dataFin);

      filter({ dataIni, dataFin });
      window.sessionStorage.setItem(
        'filter',
        JSON.stringify({ dataIni, dataFin })
      );
    }
  };

  const clearDates = () => {
    setValue('dataIni', '');
    setSituation('');
    setValue('dataFin', '');

    const dataIni = '';

    const dataFin = '';

    filter({ dataIni, dataFin });
    window.sessionStorage.removeItem('exams');
    window.sessionStorage.removeItem('filter');
  };

  const hasDataIniErros = Boolean(errors.dataIni);

  const hasDataFimErros = Boolean(errors.dataFin);

  const {
    onBlur: onBlurIni,
    pattern: patternIni,
    required: requiredIni,
    onChange: onChangeIni,
    name: nameIni,
  } = register('dataIni');

  const {
    onBlur: onBlurFim,
    pattern: patternFim,
    required: requiredFim,
    onChange: onChangeFim,
    name: nameFim,
  } = register('dataFin');
  return (
    <Box>
      <Box
        alignItems="flex-end"
        display="flex"
        justifyContent="space-between"
        marginBottom={4}
        marginTop={12}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          alignItems="flex-end"
          autoComplete="off"
          component="form"
          display="flex"
          gap={4}
          justifyContent="space-between"
        >
          <Box alignItems="center" display="flex" gap={4}>
            <span style={{ color: '#525B65' }}>Período:</span>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap={4}
              justifyContent="center"
            >
              <Box
                marginTop={!hasDataIniErros && hasDataFimErros ? '-17px' : 0}
                width="200px"
              >
                <ReactInputMask
                  defaultValue={convertBackDate(defaultValue?.dataIni)}
                  mask="99/99/9999"
                  name={nameIni}
                  onBlur={onBlurIni}
                  onChange={onChangeIni}
                  pattern={patternIni}
                  required={requiredIni}
                >
                  <TextField
                    autoComplete="off"
                    error={hasDataIniErros}
                    fullWidth
                    {...register('dataIni')}
                    helperText={hasDataIniErros && errors.dataIni?.message}
                    label="Início"
                    placeholder="00/00/0000"
                    type="tel"
                    variant="standard"
                  />
                </ReactInputMask>
              </Box>
              <span style={{ color: '#525B65' }}>a</span>
              <Box
                marginTop={hasDataIniErros && !hasDataFimErros ? '-17px' : 0}
                width="200px"
              >
                <ReactInputMask
                  defaultValue={convertBackDate(defaultValue?.dataFin)}
                  mask="99/99/9999"
                  name={nameFim}
                  onBlur={onBlurFim}
                  onChange={onChangeFim}
                  pattern={patternFim}
                  required={requiredFim}
                >
                  <TextField
                    autoComplete="off"
                    error={hasDataFimErros}
                    fullWidth
                    sx={{
                      color: '#525B65',
                      input: { color: '#525B65' },
                      '::placeholder': { color: '#525B65' },
                      ':hover': { color: '#525B65' },
                    }}
                    {...register('dataFin')}
                    helperText={hasDataFimErros && errors.dataFin?.message}
                    label="Fim"
                    placeholder="00/00/0000"
                    type="tel"
                    variant="standard"
                  />
                </ReactInputMask>
              </Box>
            </Box>

            <Button
              disableElevation
              style={{ backgroundColor: '#525B65' }}
              sx={{ height: '40px', paddingX: 8 }}
              type="submit"
              variant="contained"
            >
              pesquisar
            </Button>
          </Box>
        </Box>

        <Box alignItems="flex-end" display="flex" flexDirection="column">
          <Box
            alignItems="center"
            display="flex"
            gap={4}
            justifyContent="space-between"
          >
            <Typography color="text.primary" textAlign="left">
              Pesquisar
            </Typography>
            <TextField
              label=""
              onChange={(e) => search(e.target.value)}
              size="small"
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
      <Select
        items={[
          { label: 'Apto', value: 'APTO' },
          { label: 'Não Apto', value: 'NÃO APTO' },
        ]}
        label="Situação"
        setValue={setSituation}
        style={{ width: 150 }}
        value={situation}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginBottom={2}
      >
        <Typography color="text.primary" marginTop={4} textAlign="left">
          Total: {total} registros
        </Typography>
        <Button
          color="inherit"
          disableElevation
          onClick={clearDates}
          sx={{ height: '40px', paddingX: 8 }}
          variant="text"
        >
          Limpar filtros
        </Button>
      </Box>
    </Box>
  );
};
