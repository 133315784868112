import { Navigate, Outlet } from 'react-router-dom';

import { useSession } from 'context/AuthProvider';

const PrivateRoute = () => {
  const { isAuthenticated } = useSession();

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
