import React from 'react';

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Stack,
  Typography,
} from '@mui/material';
import { NavigateNext as NavigateNextIcon } from 'ui/components/Icon';

import { BreadcrumbsProps } from './Breadcrumbs.types';

export const Breadcrumbs = ({ items, ...props }: BreadcrumbsProps) => {
  const fillBreadcrumbs = items.map((breadcrumb, index) => (
    <Link
      color="inherit"
      href={breadcrumb.route}
      key={breadcrumb.route}
      underline="hover"
    >
      {breadcrumb.text}
    </Link>
  ));

  return (
    <Stack spacing={2}>
      <MuiBreadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        {...props}
      >
        {fillBreadcrumbs}
      </MuiBreadcrumbs>
    </Stack>
  );
};
