/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { client } from 'adapters/http';
import { format } from 'date-fns';
import { formatDate } from 'helpers/date';
import { StorageKeys, useStorage } from 'hooks/useStorage';
import { ProfileDermato } from 'models/profile.model';
import { StyledControlAccess } from 'modules/MedicalExam/MedicalExam.styles';
import { Breadcrumbs, Button, Loading, Modal } from 'ui/components';

import { HeaderTable } from './fragments/Header';
import { ResetPassword } from './fragments/ResetPassword';
import { Doctor } from './models/docktor';

const StyledTable = styled(Table)({
  '& .MuiTableCell-root': {
    border: '1px solid black',
  },
});

const RedTableHead = styled(TableHead)({
  backgroundColor: '#50687a',
  '& .MuiTableCell-root': {
    color: 'white',
  },
});

export const TableDoctors = () => {
  const [doctors, setDoctors] = useState<Doctor[]>();

  const [doctor, setDoctor] = useState<Doctor>();

  const [loading, setLoading] = useState(false);

  const [isOpenReset, setIsOpenReset] = useState(false);

  const [searchParams] = useSearchParams();
  const { get } = useStorage();

  const getDoctors = useCallback(() => {
    setLoading(true);
    const currentProfile = get<ProfileDermato>(StorageKeys.MANAGER);
    // const params = { uo: currentProfile?.codigoUnidade };
    client
      .get<Doctor[]>('/dermato/listaMedicos')
      .then(({ data }) => setDoctors(data))
      .finally(() => setLoading(false));
  }, []);

  const handleResetPassword = (item: Doctor) => {
    setDoctor(item);
    setIsOpenReset(true);
  };

  const filterDoctors = (value: string) =>
    doctors?.filter((item) =>
      ['nome', 'crm', 'situacao'].some((key) => {
        const doctorValue = item[key as keyof Doctor];
        if (typeof doctorValue === 'string') {
          return doctorValue.toLowerCase().includes(value.toLowerCase());
        }
        return false;
      })
    );

  const handleSearch = (key: string) => {
    if (!key) getDoctors();

    const results = filterDoctors(key);

    setDoctors(results);
  };

  const handleCloseModal = () => {
    setIsOpenReset(false);
    getDoctors();
  };

  const [searchDoctors, setSearchDoctors] = useState('');
  const [filteredDoctors, setFilteredDoctors] = useState<Doctor[]>();

  const handleSearchDoctors = (e: any) => {
    const searchTerm = e.target.value;
    setSearchDoctors(searchTerm);

    const filteredItems = doctors?.filter((item) =>
      ['nome', 'crm', 'situacao'].some((key) => {
        const doctorValue = item[key as keyof Doctor];
        if (typeof doctorValue === 'string') {
          return doctorValue.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      })
    );

    setFilteredDoctors(filteredItems);
  };

  useEffect(() => {
    getDoctors();
  }, [getDoctors, searchParams]);

  return (
    <Loading active={loading}>
      <StyledControlAccess>
        <Breadcrumbs items={[{ text: 'Página inicial', route: '/' }]} />
        <Typography
          color="text.primary"
          fontSize="2em"
          fontWeight={600}
          textAlign="left"
        >
          Senha do médico
        </Typography>

        {filteredDoctors ? (
          <HeaderTable
            search={handleSearch}
            total={filteredDoctors?.length ?? 0}
          />
        ) : (
          <HeaderTable search={handleSearch} total={doctors?.length ?? 0} />
        )}

        <Box
          alignItems="center"
          display="flex"
          gap={4}
          justifyContent="flex-end"
          sx={{ mb: '1rem' }}
        >
          <Typography color="text.primary" textAlign="left">
            Pesquisar
          </Typography>
          <TextField
            onChange={handleSearchDoctors}
            size="small"
            type="text"
            value={searchDoctors}
            variant="outlined"
          />
        </Box>
        {/* <ul>
          {filteredUsers?.map(user => <li key={user.id}>{user.nome}</li>)}
        </ul> */}

        <StyledTable size="small">
          <RedTableHead>
            <TableRow>
              <TableCell align="center">NOME</TableCell>
              <TableCell align="center">CRM</TableCell>
              <TableCell align="center">DATA DA CRIAÇÃO DA SENHA</TableCell>
              <TableCell align="center">ULTIMA ALTERAÇÃO</TableCell>
              <TableCell align="center">SITUAÇÃO</TableCell>
              <TableCell align="center" />
            </TableRow>
          </RedTableHead>
          <TableBody>
            {filteredDoctors
              ? filteredDoctors?.map((medico, index) => (
                  <TableRow key={`${medico.crm}-${index}`}>
                    <TableCell align="center">
                      <Link state={medico} to="/manager/doctor/edit">
                        {medico.nome}
                      </Link>
                    </TableCell>
                    <TableCell align="center">{medico.crm}</TableCell>
                    <TableCell align="center">
                      {formatDate(medico?.dataCriacaoSenha)}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(medico?.dataUltimaAlteracao)}
                    </TableCell>
                    <TableCell align="center">
                      {medico.situacao ?? 'Não consta'}
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        color="primary"
                        disableElevation
                        onClick={() => handleResetPassword(medico)}
                        sx={{ height: '100%', paddingX: 10, mx: 4 }}
                        type="submit"
                        variant="contained"
                      >
                        REDEFINIR SENHA
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : doctors?.map((medico, index) => (
                  <TableRow key={`${medico.crm}-${index}`}>
                    <TableCell align="center">
                      <Link state={medico} to="/manager/doctor/edit">
                        {medico.nome}
                      </Link>
                    </TableCell>
                    <TableCell align="center">{medico.crm}</TableCell>
                    <TableCell align="center">
                      {formatDate(medico?.dataCriacaoSenha)}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(medico?.dataUltimaAlteracao)}
                    </TableCell>
                    <TableCell align="center">
                      {medico.situacao ?? 'Não consta'}
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        color="primary"
                        disableElevation
                        onClick={() => handleResetPassword(medico)}
                        sx={{ height: '100%', paddingX: 10, mx: 4 }}
                        type="submit"
                        variant="contained"
                      >
                        REDEFINIR SENHA
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </StyledTable>
        <Modal
          disableEscapeKeyDown
          handleClose={handleCloseModal}
          open={isOpenReset}
          title="Redefinir senha"
        >
          <ResetPassword close={handleCloseModal} doctor={doctor as Doctor} />
        </Modal>
      </StyledControlAccess>
    </Loading>
  );
};
