import { Box, Typography } from '@mui/material';
import { StyledControlAccess } from 'modules/MedicalExam/MedicalExam.styles';
import { Breadcrumbs } from 'ui/components';

import { FormAddDoctor } from './fragments/FormAddDoctor';

export const NewDoctor = () => (
  <StyledControlAccess>
    <Box margin="0 auto" maxWidth="800px">
      <Breadcrumbs
        items={[
          { text: 'Página inicial', route: '/' },
          {
            text: 'Novo cadastro de senha do médico',
            route: '/manager/doctor/new',
          },
        ]}
      />
      <Typography
        color="text.primary"
        fontSize="2em"
        fontWeight={600}
        textAlign="left"
      >
        Novo cadastro de senha do médico
      </Typography>

      <FormAddDoctor />
    </Box>
  </StyledControlAccess>
);
