import { FC } from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import { formatDate } from 'helpers/date';

import { SearchTable } from '../../SearchExam/search-table.type';

const TableStyled = styled(Table)({
  '& .MuiTableCell-root': {
    borderBottom: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    border: 'none',
  },
});

export const PersonalDataTable: FC<{ accredited: SearchTable }> = ({
  accredited,
}) => {
  const datas = [accredited];

  return (
    <Box>
      <Typography
        color="black"
        fontSize="1.5em"
        fontWeight={500}
        marginTop={5}
        textAlign="left"
      >
        Dados pessoais
      </Typography>
      <TableStyled size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="left">Data de nascimento</TableCell>
            <TableCell align="left">Idade</TableCell>
            <TableCell align="left">Gênero</TableCell>
            <TableCell align="left">Data de matricula</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datas?.map?.((data, index: number) => (
            <TableRow key={data?.credencial ?? index}>
              <TableCell align="left">{data?.nome}</TableCell>
              <TableCell align="left">
                {formatDate(data?.dataNascimento)}
              </TableCell>
              <TableCell align="left">{data?.idade} anos</TableCell>
              <TableCell align="left">{data?.genero}</TableCell>
              <TableCell align="left">
                {formatDate(data?.dataMatricula)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableStyled>
    </Box>
  );
};
