/* eslint-disable no-template-curly-in-string */
export default {
  COMMONS: {
    BACK: 'Voltar',
    CANCEL: 'Cancelar',
    SAVE: 'Salvar',
    ACCEPT: 'Aceitar',
    REFUSE: 'Recusar',
    UPDATE: 'Atualizar',
    START: 'Iniciar',
    REGISTER: 'Inserir',
    NO_DATA: 'Nenhum registro encontrado.',
    CONFIRM: 'Confirmar',
    INCLUDE: 'Incluir',
    SEARCH: 'Buscar',
    SAVE_ALTERNATE: 'Gravar',
    MIN: 'Mínimo',
    MAX: 'Máximo',
    HEIGHT: 'Altura',
    WIDTH: 'Largura',
    DEVIATION: 'Desvio',
    ACTIONS: 'Ações',
    YES: 'Sim',
    NO: 'Não',
    VALIDATE: 'Validar',
    VIEW_MORE: 'Ver mais',
    ADD: 'Adicionar',
    IMPORT: 'Importar',
    RESET: 'Reset',
    CLOSE: 'Fechar',
    DELETE: 'Excluir',
    FINISH: 'Finalizar',
    CLEAR: 'Limpar',
    BY: 'Por: ',
    SEND: 'Enviar',
    TIMEZONE_INFO: 'Todas as datas e horários seguem o horário de Brasília.',
    SELECT: 'Selecione',
    LAST_UPDATE: 'Última atualização',
    CPF: 'CPF',
    PHONE: 'Telefone',
    FILLED: 'Preenchido',
    NOT_FILLED: 'Não Preenchido',
    CONFIRM_DELETE: 'Deseja excluir o registro ?',
    EMAIL: 'E-mail',
    ROLE: 'Perfil',
    CONTINUE: 'Continuar',
    ALL: 'Todos',
    QUIT: 'Desistir',
    APPEAL: 'Recurso',
    DATE_GREATHER_THAN_INITIAL:
      'A Data selecionada tem que ser maior que a inicial',
    DATE_GREATHER_THAN_OPENING:
      'A Data selecionada tem que ser maior que a Data de Abertura',
    DATE_GREATHER_THAN_DELIVERY_OPENING:
      'A Data selecionada deve ser maior ou igual a data de abertura da entrega',
    DATE_GREATHER_THAN_EQUAL_PHASE_OPENING:
      'A Data selecionada deve ser maior ou igual a data de abertura da fase',
    DATE_LESS_THAN_EQUAL_PHASE_CLOSING:
      'A Data selecionada deve ser menor ou igual a data de fechamento da fase',
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
    OPENING_DATE_TIME: 'Data e hora de abertura',
    CLOSING_DATE_TIME: 'Data e hora de fechamento',
    REQUEST: 'Solicitar',
    TERM_SIGNATURE_CERTIFICATE: 'Comprovantes Termos e Condições',
    TERMS_AGREE: 'Declaro estar de acordo com os termos e condições do SESC.',
    GOOD_MORNING: 'Bom dia',
  },
  DASHBOARDS: {
    TITLE: 'LINKS',
    ADD_LINK: 'Adicionar link',
    MANAGER_ACCESS: 'GERENCIAMENTO DE ACESSO',
    INSERT_EMAILS: 'Inserir e-mails',
    DEFINE_RULE: 'Definir regra',
  },
};
