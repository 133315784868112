import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, TextField } from '@mui/material';
import * as yup from 'yup';

interface Inputs {
  credential: number | null;
}

interface SearchCredentialProps {
  loading: boolean;
  submit: (search: number) => void;
}

const validationSchema = yup.object().shape({
  credential: yup
    .number()
    .typeError('A credencial deve ser numeral.')
    .integer()
    .nullable(true)
    .notRequired(),
});

export const SearchCredential = forwardRef(
  (props: SearchCredentialProps, ref) => {
    const { loading, submit } = props;

    const {
      watch,
      register,
      handleSubmit,
      getValues,
      setValue,
      setFocus,
      formState: { errors },
    } = useForm<Inputs>({
      resolver: yupResolver(validationSchema),
    });

    const values = getValues();

    const onSubmit = useCallback(
      (form: typeof values) => {
        if (form?.credential) {
          submit(form?.credential);
        }
      },
      [submit]
    );

    useImperativeHandle(ref, () => ({
      clearSearch: () => {
        setValue('credential', null);
        setFocus('credential');
      },
    }));

    const credential = watch('credential');

    const hasCredentialError = Boolean(errors.credential);

    useEffect(() => {
      if (credential?.toString()?.length === 12) {
        const form = getValues();
        if (form?.credential) {
          const searchCredential = Number(
            credential?.toString()?.substring?.(0, 10)
          );
          submit(searchCredential);
          setValue('credential', searchCredential);
        }
      }
    }, [credential, getValues, submit]);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container marginBottom={10} spacing={2}>
          <Grid item xs={4}>
            <TextField
              autoFocus
              error={hasCredentialError}
              fullWidth
              helperText={hasCredentialError && errors.credential?.message}
              label="Digite ou faça a leitura da credencial"
              placeholder="Número da credencial"
              type="text"
              variant="outlined"
              {...register('credential')}
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              disabled={loading}
              fullWidth
              style={{ backgroundColor: '#525B65' }}
              sx={{ height: '56px', paddingX: 10 }}
              type="submit"
              variant="contained"
            >
              BUSCAR
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
);
