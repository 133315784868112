import { FC, useState } from 'react';

import {
  Alert,
  Box,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { differenceInMonths, isAfter } from 'date-fns';
import { formatDate } from 'helpers/date';
import { renameDoctor } from 'helpers/rename';

import { SearchTable } from '../../SearchExam/search-table.type';

const TableStyled = styled(Table)({
  '& .MuiTableCell-root': {},
  '& .MuiTableHead-root .MuiTableCell-root': {},
});

type History = SearchTable['historicoExames'][0];

interface ExamHistoryDataTableProps {
  accredited: SearchTable;
}

export const ExamHistoryDataTable: FC<ExamHistoryDataTableProps> = ({
  accredited,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [history] = useState<History[]>(accredited?.historicoExames ?? []);

  return (
    <Box>
      <Typography
        color="black"
        fontSize="1.5em"
        fontWeight={500}
        marginTop={5}
        textAlign="left"
      >
        Histórico de exames
      </Typography>
      <TableStyled>
        <TableHead>
          <TableRow>
            <TableCell align="left">Data da realização</TableCell>
            <TableCell align="left">Médico responsável</TableCell>
            <TableCell align="left">Situação</TableCell>
            <TableCell align="left">Validade</TableCell>
            <TableCell align="left">Observação</TableCell>
            <TableCell align="left"> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history?.map?.((data) => {
            const today = new Date();

            const anotherDate = new Date(data.dataRealizacao);

            const monthsDiff = differenceInMonths(today, anotherDate);

            const isOverSixMonths =
              monthsDiff <= 6 || isAfter(anotherDate, today);

            return (
              <TableRow key={data.exameId}>
                <TableCell align="left">
                  {/* {format(new Date(data.dataRealizacao), 'dd/MM/yyyy', {
                    locale: ptBR,
                  })} */}
                  {formatDate(data?.dataRealizacao)}
                </TableCell>
                <TableCell align="left">
                  {renameDoctor(data?.medico?.nome)}, CRM {data?.medico?.crm} -{' '}
                  {data?.unidadeRealizacao}
                </TableCell>
                <TableCell align="left">{data?.situacao}</TableCell>
                <TableCell align="left">{formatDate(data?.validade)}</TableCell>
                <TableCell align="left">{data?.observacao}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableStyled>

      <Snackbar
        autoHideDuration={6000}
        onClose={() => setConfirmDelete(false)}
        open={confirmDelete}
      >
        <Alert
          onClose={() => setConfirmDelete(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Excluido com sucesso!
        </Alert>
      </Snackbar>
    </Box>
  );
};
