import { ptBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as Logo } from 'assets/img/logo-sesc.svg';

const theme = createTheme(
  {
    palette: {
      background: {
        default: '#F5F5F5',
      },
      primary: {
        main: '#004C99',
      },
      secondary: {
        main: '#2B2A2C',
        contrastText: '#ffffff',
      },
      error: {
        main: '#e74c3c',
      },
    },
    spacing: 5,
  },
  ptBR
);

export { Logo, theme, ThemeProvider };
