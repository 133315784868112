import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Button, Table, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { ExamHistoryDataTable } from 'modules/Manager/fragments/SingleExam/ExamHistoryDataTable';
import { PersonalDataTable } from 'modules/Manager/fragments/SingleExam/PersonalDataTable';
import { Breadcrumbs, Header } from 'ui/components';

const TableStyled = styled(Table)({
  '& .MuiTableCell-root': {},
  '& .MuiTableHead-root .MuiTableCell-root': {},
});

const url = process.env.REACT_APP_ENDPOINT;

export const SingleExamHistory = () => {
  const { currentCredential } = useParams();
  const [examDetails, setExamDetails] = useState<any>([]);
  const [errMessage, setErrMessage] = useState('');

  useEffect(() => {
    axios
      .get(`${url}/dermato/pesquisaExames?identificacao=${currentCredential}`)
      .then((response) => {
        setExamDetails(response.data[0]);
      })
      .catch((error) => {
        setErrMessage(error.response);
      });
  }, []);

  return (
    <>
      <Header label="Exame Dermatológico" />
      <Box sx={{ flexGrow: 1, paddingX: 8, paddingY: 5 }}>
        <Breadcrumbs
          items={[
            { text: 'Página inicial', route: '/' },
            {
              text: 'Resultado da pesquisa',
              route: '/manager/report/doctor/single',
            },
          ]}
        />
        {errMessage ? (
          <p>
            <Typography
              color="black"
              fontSize="1em"
              fontWeight={600}
              textAlign="center"
            >
              Nenhuma informação encontrada!
            </Typography>
          </p>
        ) : (
          <>
            <Typography
              color="black"
              fontSize="2em"
              fontWeight={600}
              textAlign="left"
            >
              {examDetails.nome}
            </Typography>
            <Typography
              color="black"
              fontSize="1.5em"
              fontWeight={500}
              textAlign="left"
            >
              {examDetails.categoria} - {examDetails.credencial}
            </Typography>
            <PersonalDataTable accredited={examDetails} />
            <ExamHistoryDataTable accredited={examDetails} />
          </>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        mt={10}
        sx={{ flexGrow: 1, paddingX: 8, paddingY: 5 }}
      >
        <Button
          autoFocus
          className="button-block"
          color="primary"
          onClick={() => {
            window.location.href = '/?admin=true';
          }}
          variant="contained"
        >
          VOLTAR
        </Button>
      </Box>
    </>
  );
};
