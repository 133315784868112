import { Route, Routes } from 'react-router-dom';

import {
  CategoryRangeReport,
  EditDoctor,
  Links,
  ListDoctors,
  Main,
  MedicalExamReport,
  NewDoctor,
  NotFound,
  Search,
  SignIn,
  Single,
} from 'pages';
import { SingleExamHistory } from 'pages/SingleDoctorHistory';
import { SingleExamManager } from 'pages/SingleExamManager';

import PrivateRoute from './PrivateRoute';

export const MainRoutes = () => (
  <Routes>
    <Route element={<PrivateRoute />} path="/">
      <Route element={<Main />} path="/" />
      <Route element={<Links />} path="/systems" />
      <Route element={<Search />} path="/search" />
      <Route element={<Single />} path="/single" />
      <Route
        element={<CategoryRangeReport />}
        path="/manager/category-range-report"
      />
      <Route element={<ListDoctors />} path="/manager/doctor/list" />

      <Route element={<NewDoctor />} path="/manager/doctor/new" />
      <Route element={<EditDoctor />} path="/manager/doctor/edit" />

      <Route element={<MedicalExamReport />} path="/manager/report/exam" />

      <Route
        element={<SingleExamManager />}
        path="/manager/report/exam/single"
      />

      <Route
        element={<SingleExamHistory />}
        path="/manager/exams/single/:currentCredential"
      />
    </Route>
    <Route element={<SignIn />} path="/login" />
    <Route element={<NotFound />} path="*" />
  </Routes>
);
