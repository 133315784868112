import axios from 'axios';

const baseURL = String(process.env.REACT_APP_ENDPOINT);

export const client = axios.create({
  baseURL,
  headers: {
    Accept: 'application/vnd.GitHub.v3+json',
  },
});
