import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useSession } from 'context/AuthProvider';
import { MedicalExam } from 'modules';
import { MedicalExamContextProvider } from 'modules/MedicalExam/context/MedicalExam.context';
import { Header } from 'ui/components';

export const Main = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { profile, isAuthenticatedDoctor } = useSession();

  useEffect(() => {
    const search = searchParams.get('s');
    const admin = searchParams.get('admin');
    if (profile?.perfil === 'ADMINISTRADOR' && admin) {
      return navigate('/?admin=true');
    }
    if (profile?.perfil === 'ADMINISTRADOR') {
      return navigate('/manager/report/exam');
    }

    if (search && isAuthenticatedDoctor) {
      return navigate(`/search?s=${search}`);
    }

    if (profile?.perfil === 'MEDICO' && isAuthenticatedDoctor) {
      return navigate('/');
    }

    return navigate('/login');
  }, [profile, navigate, isAuthenticatedDoctor]);

  return (
    <MedicalExamContextProvider>
      <MedicalExam type="newexam">
        <Header label="Exame Dermatológico" />
      </MedicalExam>
    </MedicalExamContextProvider>
  );
};
