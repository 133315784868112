import { Box, Modal as MuiModal } from '@mui/material';

import { ModalProps } from './Modal.types';

export const Modal = ({
  title,
  children,
  open,
  handleClose,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...props
}: ModalProps) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 0,
    pt: 10,
    px: 4,
    pb: 3,
  };

  const styleTitle = {
    position: 'absolute',
    top: -30,
    left: 'calc(50% - 200px)',
    width: 400,
    bgcolor: '#000',
    color: '#FFF',
    textAlign: 'center',
    padding: '14px 15px',
    fontSize: 20,
  };

  return (
    <MuiModal
      {...props}
      aria-describedby="parent-modal-description"
      aria-labelledby="parent-modal-title"
      onClose={handleClose}
      open={open}
    >
      <Box sx={{ ...style, width: 600 }}>
        <Box sx={styleTitle}>{title}</Box>
        {children}
      </Box>
    </MuiModal>
  );
};
