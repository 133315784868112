import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthMangerProvider } from 'context/AuthProvider';
import { theme, ThemeProvider } from 'ui/theme';

import { msalConfig } from './authConfig';
import reportWebVitals from './reportWebVitals';
import { MainRoutes } from './routes';

import 'framework/i18n';

const Bootstrap = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  // if (process.env.NODE_ENV === 'development') {
  //   // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  //   const { worker } = require('mocks/browser');
  //   worker.start();
  // }

  render(
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <AuthMangerProvider>
            <CssBaseline />
            <MainRoutes />
          </AuthMangerProvider>
        </BrowserRouter>
      </ThemeProvider>
    </MsalProvider>,
    document.getElementById('root')
  );

  reportWebVitals();
};
Bootstrap();
