import { FC } from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

const TableStyled = styled(Table)({
  '& .MuiTableCell-root': {
    borderBottom: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    border: 'none',
  },
});

export const AddressDataTable: FC<{ contacts: any }> = ({ contacts }) => (
  <Box>
    <Typography
      color="black"
      fontSize="1.5em"
      fontWeight={500}
      marginTop={5}
      textAlign="left"
    >
      Contatos
    </Typography>
    <TableStyled size="small">
      <TableHead>
        <TableRow>
          <TableCell>Endereço</TableCell>
          <TableCell align="left">Complemento</TableCell>
          <TableCell align="left">Bairro</TableCell>
          <TableCell align="left">Cidade</TableCell>
          <TableCell align="left">Cep</TableCell>
          <TableCell align="left">Telefone</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {contacts?.map?.((data: any, index: number) => (
          <TableRow key={data?.telefone ?? index}>
            <TableCell align="left">{data?.endereco}</TableCell>
            <TableCell align="left">{data?.complemento}</TableCell>
            <TableCell align="left">{data?.bairro}</TableCell>
            <TableCell align="left">{data?.cidade} </TableCell>
            <TableCell align="left">{data?.cep}</TableCell>
            <TableCell align="left">{data?.telefone}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableStyled>
  </Box>
);
